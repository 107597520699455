export const data = {
  banner: [
    {
      to: "/category",
      img: "../../images/banner/test.jpg",
      title: "Unveil Your Signature Style with CartCanvas",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      to: "/category",
      img: "../../images/banner/model2.jpg",
      title: "Fashion is the art, and you are the canvas",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      to: "/category",
      img: "../../images/banner/model3.jpg",
      title: "Elegance Redefined: Where Style Knows No Limits",
      description: " Praesent commodo cursus magna, vel scelerisque nisl.",
    },
  ],
  iconProducts: [
    {
      to: "/category",
      img: "https://images.unsplash.com/photo-1495121605193-b116b5b9c5fe?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      img2: "https://images.unsplash.com/photo-1547996160-81dfa63595aa?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Dresses & Jeans",
      title2: "Luxurios Watches",
      text: " Upto 20% off",
      text2: " Upto 20% off",
      tips: "Sony, Dell, Lenovo",
      cssClass: "text-primary",
    },
    {
      to: "/category",
      img: "https://images.unsplash.com/photo-1613482184972-f9c1022d0928?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      img2: "https://images.unsplash.com/photo-1566977776052-6e61e35bf9be?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Bags and Totes",
      title2: "Premium Perfumes",
      text: " Upto 50% off",
      text2: " Upto 50% off",
      tips: "Sony, Dell, Lenovo",
      cssClass: "text-secondary",
    },
    {
      to: "/category",
      img: "https://images.unsplash.com/photo-1695527081756-6e15ed27c6a3?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      img2: "https://images.unsplash.com/photo-1608256246200-53e635b5b65f?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Hair Care Products",
      title2: "Boots & Sandals",
      text: " Upto 20% off",
      text2: " Upto 20% off",
      tips: "Sony, Dell, Lenovo",
      cssClass: "text-danger",
    },
  ],
  products: [
    {
      id: 1,
      sku: "FAS-01",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_red_480x400.webp",
      price: 180,
      originPrice: 200,
      discountPrice: 20,
      discountPercentage: 10,
      isNew: true,
      isHot: false,
      star: 4,
      isFreeShipping: true,
      description:
        "Nulla sodales sit amet orci eu vehicula. Curabitur metus velit, fermentum a velit ac, sodales egestas lacus. Etiam congue velit vel luctus dictum. Pellentesque at pellentesque sapien.",
    },
    {
      id: 2,
      sku: "FAS-02",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_grey_480x400.webp",
      price: 475,
      originPrice: 0,
      discountPrice: 0,
      discountPercentage: 0,
      isNew: false,
      isHot: true,
      star: 3,
      isFreeShipping: true,
      description:
        "Maecenas suscipit volutpat gravida. Nulla hendrerit nisi a lectus blandit aliquam. Integer enim magna, consequat sed justo nec, auctor sagittis urna.",
    },
    {
      id: 3,
      sku: "FAS-03",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_black_480x400.webp",
      price: 1900,
      originPrice: 2000,
      discountPrice: 100,
      discountPercentage: 0,
      isNew: true,
      isHot: true,
      star: 2,
      isFreeShipping: true,
      description:
        "Vivamus sapien eros, molestie sed lacus vitae, lacinia volutpat ipsum. Nam sollicitudin lorem eget ornare vulputate.",
    },
    {
      id: 4,
      sku: "FAS-04",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_green_480x400.webp",
      price: 10,
      originPrice: 0,
      discountPrice: 0,
      discountPercentage: 0,
      isNew: false,
      isHot: false,
      star: 0,
      isFreeShipping: false,
      description:
        "Morbi lobortis velit non consectetur porta.|Duis auctor risus ac purus vehicula tempor.|Fusce at ipsum a leo tempor malesuada.|Curabitur tincidunt justo vel volutpat suscipit.",
    },
    {
      id: 1,
      sku: "FAS-01",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_red_480x400.webp",
      price: 180,
      originPrice: 200,
      discountPrice: 20,
      discountPercentage: 10,
      isNew: true,
      isHot: false,
      star: 4,
      isFreeShipping: true,
      description:
        "Nulla sodales sit amet orci eu vehicula. Curabitur metus velit, fermentum a velit ac, sodales egestas lacus. Etiam congue velit vel luctus dictum. Pellentesque at pellentesque sapien.",
    },
    {
      id: 1,
      sku: "FAS-01",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_red_480x400.webp",
      price: 180,
      originPrice: 200,
      discountPrice: 20,
      discountPercentage: 10,
      isNew: true,
      isHot: false,
      star: 4,
      isFreeShipping: true,
      description:
        "Nulla sodales sit amet orci eu vehicula. Curabitur metus velit, fermentum a velit ac, sodales egestas lacus. Etiam congue velit vel luctus dictum. Pellentesque at pellentesque sapien.",
    },
    {
      id: 1,
      sku: "FAS-01",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_red_480x400.webp",
      price: 180,
      originPrice: 200,
      discountPrice: 20,
      discountPercentage: 10,
      isNew: true,
      isHot: false,
      star: 4,
      isFreeShipping: true,
      description:
        "Nulla sodales sit amet orci eu vehicula. Curabitur metus velit, fermentum a velit ac, sodales egestas lacus. Etiam congue velit vel luctus dictum. Pellentesque at pellentesque sapien.",
    },
    {
      id: 1,
      sku: "FAS-01",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_red_480x400.webp",
      price: 180,
      originPrice: 200,
      discountPrice: 20,
      discountPercentage: 10,
      isNew: true,
      isHot: false,
      star: 4,
      isFreeShipping: true,
      description:
        "Nulla sodales sit amet orci eu vehicula. Curabitur metus velit, fermentum a velit ac, sodales egestas lacus. Etiam congue velit vel luctus dictum. Pellentesque at pellentesque sapien.",
    },
    {
      id: 1,
      sku: "FAS-01",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_red_480x400.webp",
      price: 180,
      originPrice: 200,
      discountPrice: 20,
      discountPercentage: 10,
      isNew: true,
      isHot: false,
      star: 4,
      isFreeShipping: true,
      description:
        "Nulla sodales sit amet orci eu vehicula. Curabitur metus velit, fermentum a velit ac, sodales egestas lacus. Etiam congue velit vel luctus dictum. Pellentesque at pellentesque sapien.",
    },
    {
      id: 1,
      sku: "FAS-01",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_red_480x400.webp",
      price: 180,
      originPrice: 200,
      discountPrice: 20,
      discountPercentage: 10,
      isNew: true,
      isHot: false,
      star: 4,
      isFreeShipping: true,
      description:
        "Nulla sodales sit amet orci eu vehicula. Curabitur metus velit, fermentum a velit ac, sodales egestas lacus. Etiam congue velit vel luctus dictum. Pellentesque at pellentesque sapien.",
    },
    {
      id: 1,
      sku: "FAS-01",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_red_480x400.webp",
      price: 180,
      originPrice: 200,
      discountPrice: 20,
      discountPercentage: 10,
      isNew: true,
      isHot: false,
      star: 4,
      isFreeShipping: true,
      description:
        "Nulla sodales sit amet orci eu vehicula. Curabitur metus velit, fermentum a velit ac, sodales egestas lacus. Etiam congue velit vel luctus dictum. Pellentesque at pellentesque sapien.",
    },
  ],
  blogBanner: [
    {
      to: "/blog/detail",
      img: "../../images/blog/nature-1.webp",
      title: "First slide label",
      description: "Nulla vitae elit libero, a pharetra augue mollis interdum",
    },
    {
      to: "/blog/detail",
      img: "../../images/blog/nature-2.webp",
      title: "Second slide label",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      to: "/blog/detail",
      img: "../../images/blog/nature-3.webp",
      title: "Third slide label",
      description: "Praesent commodo cursus magna, vel scelerisque nisl.",
    },
  ],
  blogList: [
    {
      to: "/blog/detail",
      img: "../../images/blog/nature-1.webp",
      title:
        "It is a long established fact that a reader will be distracted by the readable content",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      publishDate: "Jul 05, 2020",
      tags: ["Branding", "Design"],
      commentCount: 2,
    },
    {
      to: "/blog/detail",
      img: "../../images/blog/nature-2.webp",
      title:
        "Contrary to popular belief, Lorem Ipsum is not simply random text",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour",
      publishDate: "Aug 05, 2020",
      tags: ["Branding", "Design"],
      commentCount: 3,
    },
    {
      to: "/",
      img: "../../images/blog/nature-3.webp",
      title: "The standard chunk of Lorem Ipsum used since the 1500s",
      description:
        "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.",
      publishDate: "Sep 05, 2020",
      tags: ["Branding", "Design"],
      commentCount: 4,
    },
  ],
};